import { Stack } from "@mantine/core"
import {
  IconBuilding,
  IconClipboardCheck,
  IconFileInvoice,
  IconFolderOpen,
  IconLayoutGrid,
  IconTag,
} from "@tabler/icons-react"
import { useTranslation } from "react-i18next"

import { NavBarItem } from "../components/layout/NavBarItem"
import { routes } from "../utils/constants"
import { NavBarItemProps } from "../utils/constants/useNavbarItems"

interface Props {
  readonly isKioskAdmin: boolean
}

export default function KioskNavbar({ isKioskAdmin }: Props) {
  const { t } = useTranslation("common")

  const navbarItems: NavBarItemProps[] = [
    {
      label: t("routes.dashboard"),
      href: routes.DASHBOARD.path,
      icon: IconLayoutGrid,
    },
    {
      label: t("routes.csrd"),
      href: routes.CSRD.path.replace("/:datoTopicId?", ""),
      icon: IconFileInvoice,
    },
    {
      label: t("routes.tasks"),
      href: routes.TASKS.path,
      icon: IconClipboardCheck,
    },
    {
      label: t("routes.dimensions"),
      href: routes.DIMENSIONS.path,
      icon: IconTag,
    },
    {
      label: t("routes.sources"),
      href: routes.SOURCES.path,
      icon: IconFolderOpen,
      subItems: [
        { label: t("routes.sourcesAll"), href: routes.SOURCES.path },
        { label: t("routes.surveys"), href: routes.SURVEYS.path },
      ],
    },
  ]

  if (isKioskAdmin) {
    navbarItems.splice(4, 0, {
      label: t("routes.companies"),
      href: routes.COMPANIES.path,
      icon: IconBuilding,
    })
  }

  return (
    <Stack gap={8} py={16}>
      {navbarItems.map((item) => (
        <NavBarItem key={item.label} {...item} />
      ))}{" "}
    </Stack>
  )
}
