import { AppShell } from "@mantine/core"
import { Outlet } from "react-router-dom"

import { User } from "@kiosk/types/user"

import AppHeader, { Title } from "../components/AppHeader/AppHeader"

interface Props {
  readonly title: Title
  readonly user: User & { token: string }
}

export default function ContributorLayout({ title, user }: Props) {
  return (
    <AppShell layout="alt">
      <AppHeader title={title} user={user} />

      <AppShell.Main mih="calc(100vh - 75px)">
        <Outlet />
      </AppShell.Main>
    </AppShell>
  )
}
