import { Tabs, Text } from "@mantine/core"
import { useNavigate } from "@remix-run/react"

interface SettingsTabProps {
  readonly isActive: boolean
  readonly href: string
  readonly label: string
  readonly value: string
}

export default function SettingsTab(props: SettingsTabProps) {
  const navigate = useNavigate()

  return (
    <Tabs.Tab
      bg="transparent"
      c={props.isActive ? "green.6" : "gray.7"}
      fw={props.isActive ? 600 : 400}
      h={46}
      onClick={() => navigate(props.href)}
      px={12}
      py={10}
      value={props.value}
    >
      <Text
        c={props.isActive ? "green.6" : "black"}
        fw={props.isActive ? "bold" : "normal"}
      >
        {props.label}
      </Text>
    </Tabs.Tab>
  )
}
