import { AppShell, Box } from "@mantine/core"
import { Outlet, useLocation, useMatches } from "@remix-run/react"
import _ from "lodash"

import { User } from "@kiosk/types/user"

import AppHeader, { Title } from "@kiosk/front/components/AppHeader/AppHeader"
import SettingsSubHeader from "@kiosk/front/components/AppHeader/SettingsSubHeader"

import Logo from "../components/Logo"

import KioskNavbar from "./KioskNavbar"

interface AppLayoutProps {
  readonly pageTitle: Title
  readonly user: User & { token: string }
}

const getSettingTab = <T,>(
  handle: unknown | null,
  key: string,
): T | undefined => {
  if (handle === null || typeof handle !== "object") return undefined

  const objectHandle = handle as Record<string, unknown>

  if (!(key in objectHandle)) return undefined

  const item = objectHandle[key]

  if (typeof item !== "function") return undefined

  return item() as T
}

export default function AppLayout(props: AppLayoutProps) {
  const { pathname } = useLocation()
  const matches = useMatches()
  const isSettings = pathname.startsWith("/settings")

  // TODO: use database, not Clerk
  const isKioskAdmin = props.user.roles.includes("KIOSK_ADMIN")

  const _matches = matches.map((match) =>
    match.handle === undefined
      ? undefined
      : getSettingTab<string>(match.handle, "breadcrumb"),
  )

  const settingTab = _matches.find((v) => v)

  const title: Title =
    _(matches)
      .filter((match) => match.handle !== undefined)
      .map((match) => getSettingTab<Title>(match.handle, "title"))
      .first() ?? props.pageTitle

  return (
    <AppShell
      header={{ height: isSettings ? 120 : 75 }}
      layout="alt"
      navbar={{ width: 270, breakpoint: "sm" }}
    >
      <AppShell.Navbar bg="gray.0" p={12} withBorder={false}>
        <Box p={16}>
          <Logo height={28} />
        </Box>

        <KioskNavbar isKioskAdmin={isKioskAdmin} />
      </AppShell.Navbar>

      <AppShell.Header withBorder={true}>
        <AppHeader title={title} user={props.user} />

        {isSettings ? <SettingsSubHeader activeTab={settingTab!} /> : null}
      </AppShell.Header>

      <AppShell.Main h="100vh">
        <Outlet context={{ user: props.user }} />
      </AppShell.Main>
    </AppShell>
  )
}
