import { Group, Text, ThemeIcon } from "@mantine/core"
import { useHover } from "@mantine/hooks"
import { NavLink, useLocation } from "@remix-run/react"
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react"
import { useCallback, useMemo } from "react"

import { NavBarItemProps } from "@kiosk/front/utils/constants/useNavbarItems"

export const NavBarItem = ({
  label,
  href,
  icon: Icon,
  subItems,
}: NavBarItemProps) => {
  const location = useLocation()
  const { ref, hovered } = useHover()

  const isActive = useMemo(
    () =>
      hovered ||
      location.pathname === href ||
      (subItems && location.pathname.includes(href)),
    [subItems, hovered, href, location.pathname],
  )

  const isActiveSubLink = useCallback(
    (subHref: string) => location.pathname === subHref,
    [location.pathname],
  )

  return (
    <>
      <NavLink style={{ textDecoration: "none" }} to={href}>
        <Group
          bg={isActive ? "green.1" : "initial"}
          justify="space-between"
          p={12}
          ref={ref}
          style={{ borderRadius: "var(--mantine-radius-md)" }}
        >
          <Group gap={12}>
            <ThemeIcon color={isActive ? "black" : "gray.5"} variant="white">
              <Icon />
            </ThemeIcon>

            <Text
              c={isActive ? "black" : "gray.5"}
              fw={isActive ? 600 : "normal"}
            >
              {label}
            </Text>
          </Group>

          {subItems && (
            <ThemeIcon color={isActive ? "black" : "gray.5"} variant="white">
              {location.pathname.includes(href) ? (
                <IconChevronDown />
              ) : (
                <IconChevronRight />
              )}
            </ThemeIcon>
          )}
        </Group>
      </NavLink>

      {subItems && location.pathname.includes(href)
        ? subItems.map(({ label: subLabel, href: subHref }) => (
            <NavLink
              key={subLabel}
              style={{ textDecoration: "none" }}
              to={subHref}
            >
              <Group
                bg={isActiveSubLink(subHref) ? "green.1" : "initial"}
                gap={12}
                p="12px 48px"
                ref={ref}
                style={{ borderRadius: "var(--mantine-radius-md)" }}
              >
                <Text
                  c={isActiveSubLink(subHref) ? "black" : "gray.5"}
                  fw={isActiveSubLink(subHref) ? 600 : "normal"}
                >
                  {subLabel}
                </Text>
              </Group>
            </NavLink>
          ))
        : null}
    </>
  )
}
